@import "rules";

.padless {
    margin: 0;
}

.down-arrow {
    width: 32px;
    height: 32px;
    animation: bounce 1s infinite;
    margin-top: 20px;
}

@keyframes bounce {
    0% {
        transform: translateY(-10px);
    } 50% {
        transform: translateY(10px);
    } 100% {
        transform: translateY(-10px);
    }
  }


.sidebar {
    &-wrap {
        position: absolute;
        left: 0;
        top: 20%;
        height: 120px;
        width: 90px;
        z-index: 999;
        opacity: 1;
        transform: translateX(-60px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        animation: fadeinleft 1s 2s;
        border-radius: 0px 15px 15px 0px;
    }
    &-icon-wrap {
        background-color: #fff;
    }
    &-item {
        height: 50px;
        width: 50px;
        margin: 5px;
        cursor: pointer;
    }
    &-open-close {
        background-color: #fff;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
}

@keyframes fadeinleft {
    0% {
        opacity: 1;
        transform: translateX(-60px);
    } 50% {
        opacity: 1;
        transform: translateX(0px);
    } 75% {
        opacity: 1;
        transform: translateX(0px);
    } 100% {
        transform: translateX(-60px);
        opacity: 1;
    }
  }

.logo-img.main {
    width: 300px;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.grid.four {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    gap: 32px 16px;

    @media (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 650px) {
        grid-template-columns: 1fr;
    }
}

.card {
    &__unit {
        background-color: #fff;
        border-radius: 0 0 0 80px;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        margin: 0 auto;
        height: 450px;
        border: 5px solid white;
    }

    &__front {
        position: relative;
        z-index: 2;
        top: 80%;
        left: 10%;
        height: 0;
        font-size: 2em;
        transition: all 0.2s ease-in-out;
        font-weight: bold;

        &:hover {
            transition: all 0.2s 0.2s ease-in-out;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
        @media (max-width: 675px) {
            max-height: 80%;
        }
    }

    &__link {
        display: inline-block;

        &.disabled{
            cursor: default;
        }
    }

    &__icon {
        width: 32px;
        fill: black;
    }
    &__icon-holder {
        margin: 10px 0;
    }

    &__bottom-holder {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    &__cta {
        border: 2px solid $nuublue;
        border-radius: 0 15px 0 0;
        padding: 5px 10px;
        color: $nuublue;
        font-weight: 500;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: white;
            background-color: $nuublue;
        }
    }

    &__price-holder {
        display: flex;
        align-items: flex-end;
    }

    &__price {
        font-size: 1.5em;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    &__price-sub {
        font-size: 0.8em;
        margin-bottom: 5px;
        margin-right: 7px;
    }

    &__holder {
        padding: 5px 20px;
        color: rgb(92, 92, 92);
        margin-bottom: 20px;
    }
    &__title {
        font-size: 1.5em;
        font-weight: bold;
    }
    &__desc {
        font-size: 0.8em;
        line-height: 1.2;
        color: rgb(134, 134, 134);
    }

    &__name {
        @media (max-width: 675px) {
            opacity: 0;
        }
    }
}

.content-wrap {
    display: flex;
    flex-direction: column;
}
.service-wrap {
    display: flex;
    flex-direction: column;
}

.hero.first {
    background-image: url(/img/espuma3.jpg);
    background-size: cover;
    background-position: center;
}
.hero.second {
    background-image: url(/img/festa3.jpg);
    background-size: cover;
    background-position: right top;
    max-height: 90vh;
}

.hero {
    background-color: $nuublue;
    position: relative;

    &.green {
        background-color: $nuugreen;
    }

    &.laranja {
        background-color: $nuuorange;
    }

    &-service-callout {
        font-size: 1.2em;
    }

    &-service-title {
        font-size: 6em;
        font-weight: 400;
        line-height: 1em;
        margin-top: 0.3em;
    }
    &-service-quote {
        font-size: 1.5em;
        font-weight: 400;
        line-height: 1.7em;
        margin-top: 0.3em;
        max-width: 800px;
        margin: 30px 0;
    }

    .container {
        @media (min-width: 360px) {
            padding: $p-xs;
        }
        @media (min-width: 768px) {
            padding: $p-s;
        }
        @media (max-width: 1024px) {
            padding: 0 30px;
        }
        @media (min-width: 1200px) {
            padding: $p-l;
        }
        @media (min-width: 1350px) {
            padding: $p-xl;
        }
        @media (min-width: 1600px) {
            padding: $p-2xl;
        }
        @media (min-width: 1800px) {
            padding: $p-3xl;
        }

        &.suites {
            & h2 {
                font-size: 4em;
                font-weight: 400;
                margin: 0 0 50px 0;
                padding: 0 0px;
                @media (max-width: 1024px) {
                    font-size: 3em;
                }
                @media (max-width: 675px) {
                    font-size: 2em;
                }
            }
            @media (max-width: 1200px) {
                padding: 0 0px;
            }
            @media (min-width: 1200px) {
                padding: 0 0px;
            }
            @media (min-width: 1550px) {
                padding: 0 120px;
            }
        }

        &.quote {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 80vh;
            box-sizing: border-box;
            padding: 3rem inherit 3rem inherit;

            @media (min-width: 360px) {
                padding: $p2-xs;
            }
            @media (min-width: 768px) {
                padding: $p2-s;
            }
            @media (min-width: 1024px) {
                padding: $p2-m;
            }
            @media (min-width: 1200px) {
                padding: $p2-l;
            }
            @media (min-width: 1350px) {
                padding: $p2-xl;
            }
            @media (min-width: 1600px) {
                padding: $p2-2xl;
            }
            @media (min-width: 1800px) {
                padding: $p2-3xl;
            }

            &.roxo {
                background-color: $nuupurple;
            }
            &.laranja {
                background-color: $nuuorange;
            }
            &.verde {
                background-color: $nuugreen;
            }

            & .centro {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-weight: 900;
                text-align: center;
            }
        }

        &.double {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 100vh;
            width: 100%;
            box-sizing: border-box;
        }

        &.blur {
            backdrop-filter: blur(10px);
        }

        .hero-inner {
            min-height: 100vh;
            padding: 3rem 0 3rem 0;
            display: flex;
            align-items: center;
            justify-self: center;

            .hero-inner-col {
                &.left {
                    display: none;
                }
                .hero-inner-title {
                    z-index: 11;
                    position: relative;
                    h1 {
                        margin: 0;

                        font-weight: 400;
                        @media (min-width: 1800px) {
                            font-size: 28px;
                        }
                    }
                }
                .hero-inner-links {
                    z-index: 11;
                    position: relative;
                    display: flex;
                    .hero-inner-link-item {
                        .hero-inner-link-item-padding {
                            position: relative;
                            &:before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: -30vh;
                                bottom: -30vh;
                                left: 0;
                                right: 0;
                            }
                        }
                        a {
                            padding: 12px 22px;
                            display: inline-block;
                            position: relative;
                            overflow: hidden;
                            text-decoration: none;
                            color: transparent;
                            transition: color 0.3s, -webkit-text-stroke 0.3s;
                            -webkit-text-stroke: 2px white;
                            @media (max-width: 768px) {
                                -webkit-text-stroke: 1px white;
                            }
                            &:hover {
                                color: white;
                                -webkit-text-stroke: 2px transparent;
                            }
                            span {
                                line-height: 1.1;
                                font-weight: 700;
                                text-shadow: none;
                                font-size: 5.8vw;
                            }
                        }
                        &:first-child {
                            a {
                                padding-left: 0;
                            }
                        }
                        &:last-child {
                            a {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }

        .hero-inner.construction {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .hero-inner-footer {
            padding-bottom: 80px;
            .hero-inner-footer-text {
                p {
                    font-size: 2.8rem;
                    margin-top: -90px;
                    width: 70%;
                    line-height: 4.4rem;
                }
            }
        }
    }
}
