@import "./components/reset.scss";
@import "./components/header.scss";
@import "./components/cursor.scss";
@import "./components/hero.scss";
@import "./components/footer.scss";
@import "./components/loco.scss";
@import "./components/text.scss";
@import "./components/overlay.scss";


*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  position: relative;
  z-index: -1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Avenir", "Poppins", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: #fff;
  background: #fff;
  font-size: 16px;

}

.loading {
  visibility: hidden;
}

.chat {
  position: fixed;
  z-index: 11;
  bottom: 48px;
  right: 48px;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 675px) {
    visibility: hidden;
  }

  svg {
    fill: #30cce5;
    width: 28px;

    
  &.roxo {
    fill: $nuupurple;
  }
  &.laranja {
    fill: $nuuorange;
  }
  &.verde {
    fill: $nuugreen;
  }
  }
}

