@keyframes reveal {
    0% {
        opacity: 0;
      transform: translate(0,100%);
    }
    50%{
        opacity: 0
    }
    100% {
        opacity: 1;
      transform: translate(0,0);
    }
  }