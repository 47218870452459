header {
  position: absolute;
  top: 60px;
  left: 60px;
  right: 60px;
  z-index: 30;

  @media (max-width: 1024px) {
    left: 30px;
}
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-inner-col {
      .header-inner-logo {
        h2 {
          line-height: 1;
          margin: 0;
          font-size: 1.5rem;
        }
      }
      .header-inner-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
          cursor: pointer;
        }
        .header-inner-nav-link {
          // &:first-child {
          //   margin-right: 60px;
          // }
          display: block;
          font-size: 1.2rem;
        }
        .header-inner-nav-menu {
          // .header-inner-nav-link {
          //   margin-right: 50px;
          // }
          display: flex;
          align-items: center;
          .header-inner-nav-menu-hamburger {
            width: 24px;
            position: fixed;
            top: 83px;
            right: 60px;
            span {
              border: 1px solid white;
              position: relative;
              display: block;
              width: 100%;
              height: 2px;
              margin: 4px 0;
            }
          }
        }
      }
    }
  }
}

.logo-img {
  width: 120px;
}