footer {
    position: sticky;
    bottom: 0;
    height: 30vh;
    @media (min-width: 1600px) {
        height: 30vh;
    }
    width: 100%;
    z-index: -1;
}

.outro {
    &-wrap {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: $nuugreen;
        padding: 0 60px;

        @media (max-width: 1024px) {
            flex-direction: column;
            justify-content: center;
        }

        @media (max-width: 500px) {
            display: flex;
        }

        @media (min-width: 1550px) {
          padding: 0 160px;
      }
        // @media (min-width: 360px) {
        //     padding: 0 30px;
        //   }
        //   @media (min-width: 768px) {
        //     padding: 0 100px;
        //   }
        //   @media (min-width: 1024px) {
        //     padding: 0 120px;
        //   }
        //   @media (min-width: 1200px) {
        //     padding: 0 60px;
        //   }
        //   @media (min-width: 1350px) {
        //     padding: 0 180px;
        //   }
        //   @media (min-width: 1600px) {
        //     padding: 0 220px;
        //   }
        //   @media (min-width: 1800px) {
        //     padding: 0 270px;
        //   }

        &.roxo {
            background-color: $nuupurple;
        }
        &.laranja {
            background-color: $nuuorange;
        }
        &.azul {
            background-color: $nuublue;
        }
    }

    &-right {
        text-align: right;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        
        @media (max-width: 500px) {
            text-align: center;
            align-items: center;
            height: 100px;
        }

        & p {
            font-size: 1.5rem;

            @media (max-width: 500px) {
                font-size: 1rem;
            }
        }
    }

    &-body {
        color: #fff;
    }
    &-header {
        line-height: 5.5em;
        @media (max-width: 1024px) {
        font-size: .7em;
      }
        @media (max-width: 500px) {
        font-size: .5em;
        text-align: center;
      }
    }
    &-body a {
        color: #fff !important;
    }
    &-body span {
        color: #fff !important;
    }
    &-mail {
        color: #fff;
    }
    &-address {
        color: #fff;
    }
    &-link {
        color: #fff !important;
    }

    &-content {
        display: flex;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        justify-content: start;
        align-items: center;
        height: 100%;

        @media (max-width: 500px) {
            justify-content: center;
            height: 50px;
            margin-bottom: 2rem;
        }
    }
    &-address {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;

        & address {
            margin-left: 30px;
            & p {
                margin: 0;
            }
        }
    }
    &-grid-address {
        margin-left: 20px;
        margin-top: 40px;
    }
    &-social {
        display: flex;
        &-row {
            display: flex;
            flex-wrap: wrap;
        }
        &-item {
            padding: 30px;
        }
    }
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    color: #fff;
    text-decoration: none;
}

footer h2 {
    font-size: 4em;
    @media (max-width: 1350px) {
        font-size: 3.5em;
        line-height: 1em;
    }
    font-weight: normal;
    margin-bottom: 0.01em;

    & a {
        font-weight: 200;
    }
}

.outro-header {
    & h2 {
        font-weight: 300;

        & a {
            font-weight: 400 !important;
        }
    }
    
}

.sliding-middle-out {
    display: inline-block;
    position: relative;
    &:hover {
        cursor: pointer;
    }
    &:after {
        content: "";
        display: block;
        margin: auto;
        height: 2px;
        width: 100%;
        background: white;
        transition: width 0.5s ease, background-color 0.5s ease;
    }
    &:hover:after {
        width: 0;
        background: white;
    }
}

.fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
}
