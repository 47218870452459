$p-3xl: 0 180px;
$p-2xl: 0 180px;
$p-xl: 0 120px;
$p-l: 0 120px;
$p-m: 0 120px;
$p-s: 0 100px;
$p-xs: 0 30px;

$sp-3xl: 0 80px;
$sp-2xl: 0 80px;
$sp-xl: 0 80px;
$sp-l: 0 60px;
$sp-m: 0 40px;
$sp-s: 0 30px;
$sp-xs: 0 30px;

$p2-3xl: 0 270px;
$p2-2xl: 0 270px;
$p2-xl: 0 180px;
$p2-l: 0 180px;
$p2-m: 0 180px;
$p2-s: 0 150px;
$p2-xs: 0 50px;

$nuublue: #30cce5;
$nuugreen: #4ee5b3;
$nuuorange: #fe7365;
$nuupurple: #b94177;
$nuublue80: rgba(48, 204, 229, .8);
$nuugreen80: rgb(78, 229, 179, .8);
$nuuorange80: rgb(254, 114, 101);
$nuupurple80: rgb(185, 65, 119, .8);
